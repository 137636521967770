import React, { useEffect } from 'react';

const APP_DETAILS = {
  // Update these with your actual app details
  IOS_APP_STORE_ID: "1660956060",
  ANDROID_PACKAGE_NAME: "com.sfs_mobile_app",
  DEEP_LINK_URL: "https://app.surgeonsforsurgeons.com",
};

export default function App() {
  useEffect(() => {
    let redirectTimeout;
    
    const handleRedirect = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isIOS = /iphone|ipad|ipod/.test(userAgent);
      
      // Store URLs
      const storeUrl = isIOS 
        ? `https://apps.apple.com/app/id${APP_DETAILS.IOS_APP_STORE_ID}`
        : `https://play.google.com/store/apps/details?id=${APP_DETAILS.ANDROID_PACKAGE_NAME}`;
      
      // Try to open the app
      const openApp = () => {
        if (isIOS) {

          // Create and trigger hidden iframe for deep linking
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = APP_DETAILS.DEEP_LINK_URL;
          document.body.appendChild(iframe);

          // Fallback to store URL after timeout
          redirectTimeout = setTimeout(() => {
            window.location.replace(storeUrl);
          }, 10000);
        }
        else {
          // Create and trigger hidden iframe for deep linking
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = APP_DETAILS.DEEP_LINK_URL;
          document.body.appendChild(iframe);
  
          // Fallback to store URL after timeout
          redirectTimeout = setTimeout(() => {
            window.location.replace(storeUrl);
          }, 2500);
        }
      };

      // Set up visibility change detection
      document.addEventListener('visibilitychange', () => {
        if (document.hidden) {
          // User switched to the app, clear the store redirect
          clearTimeout(redirectTimeout);
        }
      });

      // Start the deep linking process
      openApp();
    };

    handleRedirect();

    // Cleanup
    return () => {
      clearTimeout(redirectTimeout);
    };
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white flex items-center justify-center p-4">
      <div className="text-center">
        <div className="flex justify-center mb-8">
          <div className="w-16 h-16 relative">
            <div className="w-16 h-16 rounded-full border-4 border-blue-200 border-t-blue-500 animate-spin"></div>
          </div>
        </div>
        
        <div className="space-y-3">
          <h1 className="text-2xl font-semibold text-gray-800">
            Opening App...
          </h1>
          <p className="text-gray-600">
            Redirecting to store if app is not installed
          </p>
        </div>
      </div>
    </div>
  );
}